import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { useTranslation } from "react-i18next"
import { graphql } from 'gatsby'

const Page404 = props => {
  const {t} = useTranslation()

  return (
    <Layout {...props}>
      <Seo title={t('Page Not Found')} />
      <Divider />
      <Stack>
        <Main>
          <Section>
            <PageTitle
              header={t("Sorry, this page isn't available.")}
              subheader={t('You may have mistyped the address or the page may have moved.')} />
          </Section>
          <Section>
            <Button variant='primary' as={Link} to='/'>
              {t('Go to homepage')}
            </Button>
          </Section>
        </Main>
      </Stack>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }`  

export default Page404
